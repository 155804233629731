<template>
    <div class="scanCenter">

        <template v-if="action && action.length>0">
            <div v-if="action===this.$oucy.scanKeyOfPreBind">
                <h1 style="margin-top: 50px">温馨提示</h1>
                <div style="line-height: 100px;margin-top: 20px">
                    <i style="font-size: 100px;color: #ec971e;" class="el-icon-warning"></i>
                </div>
                <h3>您正在进行 [乐搜家] 微信账号绑定<br>确认授权绑定吗？</h3>
                <div style="padding: 30px 20px 0 20px;">
                    <el-button @click="sendConfirmBindAcc" style="width: 100%" type="primary">同意授权</el-button>
                </div>
                <div style="padding: 20px;">
                    <el-button @click="cancelAction" style="width: 100%">拒绝授权</el-button>
                </div>
            </div>
            <div v-if="action===this.$oucy.scanKeyOfPreLogin">
              <h1 style="margin-top: 50px">温馨提示</h1>
                <div style="line-height: 100px;margin-top: 20px">
                    <i style="font-size: 100px;color: #ec971e;" class="el-icon-warning"></i>
                </div>
                <h3>您正在进行 [乐搜家] 微信账号登录<br>确认授权登录吗？</h3>
                <div style="padding: 30px 20px 0 20px;">
                    <el-button @click="sendConfirmLoginAcc" style="width: 100%" type="primary">同意授权</el-button>
                </div>
                <div style="padding: 20px;">
                    <el-button @click="cancelAction" style="width: 100%">拒绝授权</el-button>
                </div>
            </div>
            <div v-if="action===this.$oucy.scanKeyOfRealNamePhoto">
              <h1 style="margin-top: 50px">温馨提示</h1>
                <div style="line-height: 100px;margin-top: 20px">
                    <template v-if="photoUploadFinish">
                        <i style="font-size: 100px;color: #1aad19;" class="el-icon-success"></i>
                    </template>
                    <template v-else>
                        <i style="font-size: 100px;color: #ec971e;" class="el-icon-warning"></i>
                    </template>
                </div>
                <template v-if="photoUploadFinish">
                    <h3>照片已经采集完成，请在PC端预览实际效果，你也可以重新拍照!</h3>
                </template>
                <template v-else>
                    <h3>您正在进行 [乐搜家] 实名认证肖像照片采集<br>确认拍照采集吗?</h3>
                </template>
                <div style="padding: 30px 20px 0 20px;">
                    <template v-if="photoUploadFinish">
                        <el-button @click="beginMakePhoto" style="width: 100%" type="primary">重新拍照</el-button>
                    </template>
                    <template v-else>
                        <el-button @click="beginMakePhoto" style="width: 100%" type="primary">开始拍照</el-button>
                    </template>
                </div>
                <div style="padding: 20px;">
                    <template v-if="photoUploadFinish">
                        <el-button @click="cancelAction" style="width: 100%">完成采集</el-button>
                    </template>
                    <template v-else>
                        <el-button @click="cancelAction" style="width: 100%">拒绝采集</el-button>
                    </template>
                </div>
            </div>
        </template>


    </div>
</template>

<script>

    import wx from 'weixin-js-sdk';
    import oucy from "../util/oucyUtil";
    import {Notification} from "element-ui";

    export default {
        name: "Index",
        data(){
          return{
              action:null,
              urlArgs: {
                  openid:null,
                  passcode:null,
                  clientUUID:null
              },
              photoUploadFinish:false
          }
        },
        created() {
            wx.ready(function () {
                wx.hideAllNonBaseMenuItem();
            });
        },
        mounted() {
            const me = this;
            const actionKey = me.$oucy.getUrlKey('action');
            const openid = me.$oucy.getUrlKey('openid');
            const passcode = me.$oucy.getUrlKey('passcode');
            const clientUUID = me.$oucy.getUrlKey('p');

            me.$oucy.loginWechat(openid, passcode).then(res=>{
                return me.initJsSdk();
            }).then(res=>{
                if(actionKey){
                    if(actionKey === me.$oucy.scanKeyOfPreBind){
                        return me.sendPreBindAcc(clientUUID);
                    } else if(actionKey === me.$oucy.scanKeyOfPreLogin){
                        return me.sendPreLoginAcc(clientUUID);
                    } else if(actionKey === me.$oucy.scanKeyOfRealNamePhoto){
                        return Promise.resolve;
                    } else if(actionKey === me.$oucy.scanKeyModifyMainWechatAccount){
                        alert('操作成功');
                        me.cancelAction();
                    }else {
                        return Promise.reject(new Error('没有获取到参数actionKey的有效值['+actionKey+']'));
                    }
                } else {
                    return Promise.reject(new Error('没有获取到参数actionKey的有效值[空值]'));
                }
            }).then(res=>{
                me.action = actionKey;
                me.urlArgs.openid = openid;
                me.urlArgs.passcode = passcode;
                me.urlArgs.clientUUID = clientUUID;
            }).catch(err=>{
                console.log(err);
            });
        },
        methods: {

            /**
             * 初始化微信jssdk
             */
            initJsSdk: function(){
                const me = this;
                return new Promise((resolve, reject) => {
                    me.$oucy.postRequest('/wechat/makeWechartJssdkConfig',{
                        weburi:window.location.href.split("#")[0]
                    }).then(res=>{
                        wx.config({
                            debug: false,
                            appId: res.appId,
                            timestamp: res.timestamp,
                            nonceStr: res.noncestr,
                            signature: res.signature,
                            jsApiList: ["chooseImage","uploadImage","hideAllNonBaseMenuItem","closeWindow"],
                        });
                        resolve(res);
                    }).catch(err=>{
                        reject(err);
                    });
                });
            },

            /**
             * 发送预绑定账号信息
             */
            sendPreBindAcc: function (clientUUID) {
                const me = this;
                return new Promise((resolve, reject) => {
                    if(clientUUID && clientUUID.length>0){
                        me.$oucy.postRequest('/client/user/userauth/preBindAcc', {
                            uuid: clientUUID
                        }).then(res=>{
                            resolve(res);
                        }).catch(err=>{
                            reject(err);
                        })
                    } else {
                        reject('没有获取到参数clientUUID的有效值');
                    }
                });
            },

            /**
             * 发送确定绑定账号信息
             */
            sendConfirmBindAcc: function () {
                const me = this;
                const clientUUID = me.urlArgs.clientUUID;
                return new Promise((resolve, reject) => {
                    if(clientUUID && clientUUID.length>0){
                        me.$oucy.postRequest('/client/user/userauth/confirmBindAcc', {
                            uuid: clientUUID
                        }).then(res=>{
                            wx.closeWindow();
                            resolve(res);
                        }).catch(err=>{
                            reject(err);
                        })
                    } else {
                        reject('没有获取到参数clientUUID的有效值');
                    }
                });
            },

            /**
             * 发送预登录账号信息
             */
            sendPreLoginAcc: function (clientUUID) {
                const me = this;
                return new Promise((resolve, reject) => {
                    if(clientUUID && clientUUID.length>0){
                        me.$oucy.postRequest('/client/user/userauth/preLoginAcc', {
                            uuid: clientUUID
                        }).then(res=>{
                            resolve(res);
                        }).catch(err=>{
                            reject(err);
                        })
                    } else {
                        reject('没有获取到参数clientUUID的有效值');
                    }
                });
            },


            /**
             * 发送确定登录账号信息
             */
            sendConfirmLoginAcc: function () {
                const me = this;
                const clientUUID = me.urlArgs.clientUUID;
                return new Promise((resolve, reject) => {
                    if(clientUUID && clientUUID.length>0){
                        me.$oucy.postRequest('/client/user/userauth/confirmLoginAcc', {
                            uuid: clientUUID
                        }).then(res=>{
                            wx.closeWindow();
                            resolve(res);
                        }).catch(err=>{
                            reject(err);
                        })
                    } else {
                        reject('没有获取到参数clientUUID的有效值');
                    }
                });
            },


            /**
             * 开始拍照
             */
            beginMakePhoto: function(){
                const me = this;
                wx.chooseImage({
                    count: 1, // 默认9
                    sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
                    sourceType: ['camera'], // 可以指定来源是相册还是相机，默认二者都有
                    success: function (res) {
                        let localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
                        wx.uploadImage({
                            localId: localIds[0], // 需要上传的图片的本地ID，由chooseImage接口获得
                            isShowProgressTips: 1, // 默认为1，显示进度提示
                            success: function (res) {
                                let serverId = res.serverId; // 返回图片的服务器端ID
                                oucy.uploadWechatImg(serverId).then(res=>{
                                    oucy.postRequest('/client/user/userauth/confirmRealNameAvata', {
                                        uuid: me.urlArgs.clientUUID,
                                        avata:res
                                    }).then(res=>{
                                        me.photoUploadFinish = true;
                                    });
                                });
                            }
                        });
                    }
                });
            },

            /**
             * 取消行为
             */
            cancelAction: function () {
                wx.closeWindow();
            }


        }
    }
</script>

<style scoped>
.scanCenter{
    text-align: center;
    padding: 25px;
}
</style>
